import { Membership, MembershipType } from "@tnt/common";

export class MembershipWithTypes extends Membership {
  type: MembershipType;
  nextType?: MembershipType;

  constructor(
    o: Partial<Membership> & { type: MembershipType; nextType?: MembershipType }
  ) {
    super(o);

    this.type = new MembershipType(o.type);
    this.nextType = o.nextType ? new MembershipType(o.nextType) : undefined;
  }
}
