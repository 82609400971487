import useSWR from "swr";

import { getMembershipsOfMember } from "services/memberships";
import useMemberId from "hooks/useMemberId";
import { MembershipWithTypes } from "model/MembershipWithTypes";

const ONE_HOUR = 3_600_000;

const useMembershipsOfMember = (): [
  MembershipWithTypes[] | undefined,
  () => void
] => {
  const [memberId] = useMemberId();
  const swr = useSWR(
    memberId ? `/api/members/${memberId}/memberships` : null,
    () => getMembershipsOfMember(memberId!),
    {
      revalidateOnMount: true,
      refreshInterval: ONE_HOUR,
      dedupingInterval: ONE_HOUR,
    }
  );
  return [swr.data, swr.mutate];
};

export default useMembershipsOfMember;
