import http from "./http";

import { Membership, MembershipFreeze } from "@tnt/common";
import { MembershipCancellationDTO } from "model/MembershipCancellationDTO";
import { MyPageMembershipDTO } from "model/MyPageMembershipDto";
import { MembershipWithTypes } from "model/MembershipWithTypes";

export const getMembershipsOfMember = (
  memberId: number
): Promise<MembershipWithTypes[]> =>
  http.get(`/mypage/members/${memberId}/memberships`).then(({ data }) =>
    data.map(
      (m: MyPageMembershipDTO) =>
        new MembershipWithTypes({
          ...m,
          type: m.type,
          nextType: m.nextType,
        })
    )
  );

export const saveMembershipFreezes = (
  freezes: MembershipFreeze[],
  membership: Membership
): Promise<MembershipFreeze[]> =>
  http
    .put(
      `/mypage/members/${membership.memberId}/memberships/${membership.id}/freezes`,
      freezes
    )
    .then(({ data }) =>
      data.map((mf: MembershipFreeze) => new MembershipFreeze(mf))
    );

export const cancelMembership = (
  membership: Membership,
  date: string,
  cancellation: MembershipCancellationDTO
): Promise<Membership> =>
  http
    .put(
      `/mypage/members/${membership.memberId}/memberships/${membership.id}/cancel?date=${date}`,
      cancellation
    )
    .then(({ data }) => new Membership(data));
