import React, { FC, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
import { MenuItem, Select } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { DATE_ISO_FORMAT, partitionDateRangeByYearMonth } from "@tnt/common";
import { makeStyles } from "theme";
import { getEarliestCancellationDate } from "helpers/membership";
import { MembershipWithTypes } from "model/MembershipWithTypes";

const useStyles = makeStyles()(({ spacing }) => ({
  info: {
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: spacing(1),
  },
}));

interface IProps {
  memberships: MembershipWithTypes[];
}

const DateStep: FC<IProps> = ({ memberships }) => {
  const { classes } = useStyles();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [nrOfExtraDateOptionsToShow, setNrOfExtraDateOptionsToShow] =
    useState(0);

  const { control, setValue } = useFormContext();

  const earliestDate = useMemo(() => {
    const latest = memberships[memberships.length - 1];
    const earliest = getEarliestCancellationDate(latest, latest.type);
    if (earliest) return earliest;
    else throw Error("No earliest cancellation date available.");
  }, [memberships]);

  const latestDate = useMemo(
    () =>
      memberships[memberships.length - 1].end === null ||
      memberships[memberships.length - 1].nextTypeId
        ? undefined
        : earliestDate,
    [earliestDate, memberships]
  );

  useEffect(() => {
    setValue("date", earliestDate);
  }, [earliestDate, setValue]);

  const getDateOptions = () => {
    const optionsUntil =
      latestDate ??
      moment(earliestDate)
        .add(2 + nrOfExtraDateOptionsToShow, "months")
        .endOf("month");
    const options = partitionDateRangeByYearMonth(
      earliestDate,
      optionsUntil
    ).map((ym) => (
      <MenuItem
        key={ym.end.format(DATE_ISO_FORMAT)}
        value={ym.end.format(DATE_ISO_FORMAT)}
      >
        {ym.end.format("D. MMMM YYYY")}
      </MenuItem>
    ));
    if (optionsUntil !== latestDate)
      options.push(
        <MenuItem key="more" value="more">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ExpandMore style={{ display: "block" }} />
          </div>
        </MenuItem>
      );
    return options;
  };

  return (
    <div>
      <div className={classes.info}>
        Hvilken dato ønsker du å avslutte medlemskapet?
      </div>

      <div>
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <Select
              fullWidth
              open={isDropdownOpen}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                const cls = (e.target as HTMLDivElement).getAttribute("class")!;
                if (cls == null) return;
                if (cls.includes("MuiSelect") && !isDropdownOpen)
                  setIsDropdownOpen(true);
                else if (
                  cls.includes("MuiBackdrop") ||
                  cls.includes("MuiMenuItem")
                )
                  setIsDropdownOpen(false);
              }}
              value={field.value}
              onChange={(e) => {
                if (e.target.value === "more")
                  setNrOfExtraDateOptionsToShow((prev) => prev + 1);
                else field.onChange(e);
              }}
              disabled={earliestDate === latestDate}
              data-testid="date-dropdown"
            >
              {getDateOptions()}
            </Select>
          )}
        />
      </div>
    </div>
  );
};

export default DateStep;
