import React, { FC, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import {
  Card,
  CardContent,
  darken,
  Grid,
  IconButton,
  lighten,
  Typography,
} from "@mui/material";
import {
  AcUnit,
  ArrowDownward,
  Check,
  Close,
  ExpandMore,
  Loop,
} from "@mui/icons-material";

import { dateRangeToText, MembershipFreeze, PriceType } from "@tnt/common";
import PriceView from "scenes/Memberships/components/MembershipView/components/PriceView";
import { makeStyles } from "theme";
import Tag from "components/Tag";
import FreezeModal from "scenes/Memberships/components/FreezeModal";
import EditButton from "components/EditButton";
import { MembershipWithTypes } from "model/MembershipWithTypes";

const useStyles = makeStyles()(
  ({ tenantId, palette, spacing, typography }) => ({
    root: {
      position: "relative",
    },
    firstTagged: {
      marginTop: spacing(1),
    },
    card: {
      backgroundColor:
        tenantId === 1
          ? lighten(palette.primary.main, 0.98)
          : darken(palette.secondary.main, 0.35),
      borderColor:
        tenantId === 1 ? lighten(palette.primary.main, 0.6) : "#bbbbbb",
    },
    tags: {
      position: "absolute",
      zIndex: 100,
      top: -10,
      left: 20,
    },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: spacing(2),
      borderBottom: "1px solid #cccccc",
      backgroundColor:
        tenantId === 1
          ? lighten(palette.primary.main, 0.9)
          : darken(palette.secondary.main, 0.2),
    },
    title: {
      fontSize: typography.pxToRem(18),
      fontWeight: 500,
    },
    row: {
      padding: spacing(2),
      borderBottom: "1px solid #cccccc",
      "&:last-of-type": {
        borderBottom: 0,
      },
      color: tenantId === 1 ? "black" : "white",
    },
    rowLabel: {
      display: "flex",
      alignItems: "center",
      color: tenantId === 1 ? darken(palette.primary.main, 0.25) : "white",
      fontWeight: 500,
    },
    rowValue: {
      textAlign: "right",
      color: tenantId === 1 ? darken(palette.primary.main, 0.25) : "white",
    },
    infiniteIcon: {
      display: "block",
    },
    expandIconContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      bottom: 16,
    },
    expandIcon: {
      border: `1px solid ${lighten(palette.primary.main, 0.6)}`,
      padding: 2,

      "&, &:focus, &:active, &:visited": {
        backgroundColor: lighten(palette.primary.main, 0.98),
      },

      "&:hover": {
        backgroundColor:
          tenantId === 1
            ? lighten(palette.primary.main, 0.8)
            : darken("#ffffff", 0.2),
      },

      "& > svg": {
        color: tenantId === 1 ? "inherit" : "black",
      },
    },
    flipped: {
      transform: "scaleY(-1)",
    },
  })
);

interface IProps {
  isFirst?: boolean;
  membership: MembershipWithTypes;
  isNext?: boolean;
}

const MembershipView: FC<IProps> = ({ isFirst, membership, isNext }) => {
  const { classes } = useStyles();

  const [showDescription, setShowDescription] = useState(false);
  const [freezeInModal, setFreezeInModal] = useState<MembershipFreeze>();

  const isFreezed = membership.isFreezedAtDate(moment());
  const isActive = !isFreezed && membership.isActive();
  const isUpcoming = isNext || moment(membership.start).isAfter(moment());
  const isDone = !isFreezed && !isActive && !isUpcoming;

  const tagged = isFreezed || isActive || isUpcoming || isDone;

  return (
    <div
      className={clsx(classes.root, tagged && isFirst && classes.firstTagged)}
    >
      <div className={classes.tags}>
        {isFreezed && (
          <Tag color="#3f75bd">
            <AcUnit
              style={{
                display: "inline-block",
                height: 14,
                width: 14,
                position: "relative",
                top: 2,
                marginRight: 4,
              }}
            />
            Fryst
          </Tag>
        )}

        {isActive && (
          <Tag color="green">
            <Check
              style={{
                display: "inline-block",
                height: 14,
                width: 14,
                position: "relative",
                top: 2,
                marginRight: 4,
              }}
            />
            Aktivt
          </Tag>
        )}

        {isUpcoming && (
          <Tag color="#f1a111">
            <ArrowDownward
              style={{
                display: "inline-block",
                height: 14,
                width: 14,
                position: "relative",
                top: 2,
                marginRight: 4,
              }}
            />
            Kommende
          </Tag>
        )}

        {isDone && (
          <Tag color="#d0312d">
            <Close
              style={{
                display: "inline-block",
                height: 14,
                width: 14,
                position: "relative",
                top: 2,
                marginRight: 4,
              }}
            />
            Avsluttet
          </Tag>
        )}
      </div>

      <Card
        className={classes.card}
        style={{ borderWidth: isActive || isFreezed ? 2 : 1 }}
        variant="outlined"
        data-testid="membership-type-view"
      >
        <div className={classes.header}>
          <Typography className={classes.title} component="h6">
            {membership.type.name}
          </Typography>
        </div>

        <CardContent style={{ padding: 0 }}>
          <Grid container>
            <Grid item container xs={12} className={classes.row}>
              <Grid item xs={3} className={classes.rowLabel}>
                Pris
              </Grid>

              <Grid item xs={9} className={classes.rowValue}>
                <PriceView
                  price={membership.type.priceAtDate(moment()) ?? 0}
                  prefix="kr"
                  postfix={
                    membership.type.priceType === PriceType.MONTHS
                      ? "/ mnd."
                      : undefined
                  }
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} className={classes.row}>
              <Grid item xs={3} className={classes.rowLabel}>
                Start
              </Grid>

              <Grid
                item
                xs={9}
                className={classes.rowValue}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div>{moment(membership.start).local().format("LL")}</div>
              </Grid>
            </Grid>

            <Grid item container xs={12} className={classes.row}>
              <Grid item xs={3} className={classes.rowLabel}>
                Slutt
              </Grid>

              <Grid
                item
                xs={9}
                className={classes.rowValue}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div>
                  {membership.end ? (
                    moment(membership.end).local().format("LL")
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Loop
                        className={classes.infiniteIcon}
                        style={{ display: "inline-block", marginRight: 8 }}
                      />
                      (
                      <span style={{ fontStyle: "italic" }}>
                        Løper til oppsigelse
                      </span>
                      )
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>

            {membership.freezes.length > 0 && (
              <Grid item container spacing={1} xs={12} className={classes.row}>
                <Grid
                  item
                  xs={12}
                  className={classes.rowLabel}
                  style={{ justifyContent: "center" }}
                >
                  Fryseperioder
                </Grid>

                {membership.freezes
                  .sort((a, b) => (moment(a.end).isBefore(b.end) ? -1 : 1))
                  .map((f) => (
                    <Grid
                      item
                      xs={12}
                      className={classes.rowValue}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={f.start}
                    >
                      <div>
                        {dateRangeToText(moment(f.start), moment(f.end))}
                      </div>

                      {moment().startOf("day").isSameOrBefore(f.end) && (
                        <EditButton
                          onClick={() => setFreezeInModal(f)}
                          dataTestId="edit-freeze-button"
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>
            )}

            {showDescription && (
              <Grid item container xs={12} className={classes.row}>
                {membership.type.comment}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      <div className={classes.expandIconContainer}>
        <IconButton
          className={clsx(
            classes.expandIcon,
            showDescription && classes.flipped
          )}
          onClick={() => setShowDescription((sd) => !sd)}
        >
          <ExpandMore />
        </IconButton>
      </div>

      {freezeInModal && (
        <FreezeModal
          freeze={freezeInModal}
          membership={membership}
          onClose={() => setFreezeInModal(undefined)}
        />
      )}
    </div>
  );
};

export default MembershipView;
