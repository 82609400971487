import React, { useState } from "react";
import {
  AddCardOutlined,
  CreditScoreOutlined,
  MeetingRoomOutlined,
} from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";

import { AuthenticationTokenType } from "@tnt/common";
import { makeStyles } from "theme";
import useMember from "hooks/useMember";
import useMembershipsOfMember from "hooks/useMembershipsOfMember";
import Scene from "components/Scene";
import AuthenticationToken from "./components/AuthenticationToken";
import ActivateCardModal from "./components/ActivateCardModal";
import useAuthenticationTokens from "hooks/useAuthenticationTokens";
import OrderNewCardModal from "./components/OrderNewCardModal";

const useStyles = makeStyles()(({ spacing }) => ({
  sectionTitle: {
    width: "100%",
    textAlign: "center",
    fontFamily: "arial",
    fontSize: "1.4rem",
    color: "black",
    marginBottom: spacing(2),
    userSelect: "none",
  },
  authenticationTokens: {
    maxWidth: 360,
  },
  noTokens: {
    textAlign: "center",
    fontStyle: "italic",
  },
}));

const Access = () => {
  const { classes } = useStyles();

  const { user } = useAuth0();

  const member = useMember();
  const [authenticationTokens] = useAuthenticationTokens();
  const [membershipsOfMember] = useMembershipsOfMember();

  const [isActivateCardModalOpen, setActivateCardModalOpen] = useState(false);
  const [isOrderNewCardModalOpen, setOrderNewCardModalOpen] = useState(false);

  const sortedMembershipsOfMember = membershipsOfMember?.sort((a, b) =>
    moment(a.start).isBefore(b.start) ? -1 : 1
  );
  const activeOrUpcomingMembership = sortedMembershipsOfMember?.find(
    (ms) => ms.end === null || moment(ms.end).isAfter(moment())
  );

  // Currently, the only startup product used is an access card.
  const hasMembershipWithStartupProduct =
    !!activeOrUpcomingMembership?.type.startupProductName;

  const hasPinToken =
    authenticationTokens?.find(
      (at) => at.type === AuthenticationTokenType.PIN
    ) !== undefined;
  const hasNoTokens = authenticationTokens?.length === 0;
  const canActivateRfid =
    hasMembershipWithStartupProduct && (hasPinToken || hasNoTokens);

  const hasRfidToken =
    authenticationTokens?.find(
      (at) => at.type === AuthenticationTokenType.RFID
    ) !== undefined;
  // Only the guardian of a guardianed member can order a new rfid token,
  // not the member itself, as there is a cost associated.
  const hasNoGuardianOrUserIsGuardian =
    !member?.guardian || member?.guardian.auth0UserId === user?.sub;
  const canRequestRfid =
    hasMembershipWithStartupProduct &&
    hasRfidToken &&
    hasNoGuardianOrUserIsGuardian;

  const hasUpcomingToken =
    authenticationTokens?.find((at) =>
      moment(at.validFrom).utc().isAfter(moment().utc())
    ) !== undefined;

  return (
    <Scene
      title="Adgang"
      icon={
        <MeetingRoomOutlined fontSize="large" style={{ color: "#3399FF" }} />
      }
      actions={
        !hasUpcomingToken && (canActivateRfid || canRequestRfid) ? (
          <Stack
            direction="row"
            justifyContent="center"
            style={{ width: "100%" }}
          >
            {canActivateRfid && (
              <Button
                variant="contained"
                disableElevation
                color="success"
                startIcon={<CreditScoreOutlined />}
                onClick={() => setActivateCardModalOpen(true)}
                size="large"
                data-testid="activate-card-button"
              >
                Aktiver kort
              </Button>
            )}

            {canRequestRfid && (
              <Button
                variant="contained"
                disableElevation
                color="warning"
                startIcon={<AddCardOutlined />}
                onClick={() => setOrderNewCardModalOpen(true)}
                size="large"
                data-testid="request-card-button"
              >
                Nytt kort
              </Button>
            )}
          </Stack>
        ) : undefined
      }
    >
      <div className={classes.sectionTitle}>Bevis</div>

      <Grid container spacing={4} className={classes.authenticationTokens}>
        {authenticationTokens?.map((at) => (
          <Grid item xs={12} key={at.id}>
            <AuthenticationToken authenticationToken={at} />
          </Grid>
        ))}

        {authenticationTokens?.length === 0 && (
          <Grid item xs={12} className={classes.noTokens}>
            Du har ingen adgangsbevis.
          </Grid>
        )}
      </Grid>

      <ActivateCardModal
        open={isActivateCardModalOpen}
        onClose={() => setActivateCardModalOpen(false)}
      />

      <OrderNewCardModal
        open={isOrderNewCardModalOpen}
        onClose={() => setOrderNewCardModalOpen(false)}
      />
    </Scene>
  );
};

export default Access;
